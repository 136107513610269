$(document).on('change', '#purchase_order_company_id', function (e) {
  var companyId = $(this).find(':selected').val();

  $.ajax({
    method: 'GET',
    url: `/procurement/companies/${companyId}/offices`,
    dataType: 'json',
    success: function(data, status, xhr) {
      options = '<option value>-- Select Office --</option>';

      $.each(data['offices'], function(index, office) {
        options += `<option value='${office.id}'>${office.name}</option>`;
      });

      $('#purchase_order_office_id').html(options);
    }
  });
});

$(document).on('change', '.purchase-order-item-selection', function (e) {
  e.preventDefault();
  var itemId = $(this).find(':selected').val();
  var uniqueId = $(this).attr('id').split('_')[6];

  $.ajax({
    method: 'GET',
    url: `/procurement/items/${itemId}/item_catalogues`,
    dataType: 'json',
    success: function(data, status, xhr) {
      options = '<option value>-- Item Catalogue --</option>';
      $.each(data['item_catalogues'], function(index, item_catalogue) {
        options += `<option value='${item_catalogue.id}'>${item_catalogue.brand_with_cat_no}</option>`;
      });

      $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_item_catalogue_id`).html(options);
    }
  });

  $.ajax({
    method: 'GET',
    url: `/procurement/items/${itemId}/uoms`,
    dataType: 'json',
    success: function(data, status, xhr) {
      options = '<option value>-- UOM --</option>';

      $.each(data['uoms'], function(index, uom) {
        options += `<option value='${uom.id}'>${uom.short_name}</option>`;
      });

      $(`#purchase_order_purchase_order_items_attributes_${uniqueId}_uom_id`).html(options);
    }
  });
});
