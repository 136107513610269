$(document).on('turbolinks:load', function() {
  $('.alert .close').click(function() {
    $(this).parent().slideUp();
  });

  $('.sidebar-menu-link').click(function() {
    $(this).parent().find('.sidebar-submenu-list').slideToggle();
  });

  // while in smaller device, do not save the sidebar status in backend
  $('.hamburger-icon').click(function() {
    if ($(window).width() < 991) {
      $('.wrapper').find('.sidebar').removeClass('minified');
      $('.wrapper').find('.page-wrapper').addClass('expanded');
      $('.wrapper').find('.page-wrapper').append('<div class="sidebar-backdrop" />');
    } else {
      $.ajax({
        method: 'GET',
        url: `/toggle_collapse`,
        dataType: 'json',
        success: function(data, status, xhr) {
          $('.wrapper').find('.sidebar').toggleClass('minified');
          $('.wrapper').find('.page-wrapper').toggleClass('expanded');
        }
      });
    }
  });

  // needs delegation "on" call since 'sidebar-backdrop' is created dynamically
  $('.page-wrapper').on('click', '.sidebar-backdrop', function() {
    $(this).remove();
    $('.wrapper').find('.sidebar').addClass('minified');
  });

  function closeSidebarInMobileIfAlreadyOpen() {
    if ($(window).width() <= 991) {
      if (!$('.sidebar').hasClass('minified')) {
        $('.wrapper').find('.sidebar').addClass('minified');
        $('.wrapper').find('.page-wrapper').addClass('expanded');
      }
    } else {
      $('.wrapper').find('.sidebar-backdrop').remove();
    }
  }

  closeSidebarInMobileIfAlreadyOpen();

  $(window).resize(function() {
    closeSidebarInMobileIfAlreadyOpen();
  });

  $('.sidebar').hover(function() {
    const minified = $(this).hasClass('minified');

    if (minified) {
      $(this).addClass('hover');
      $('.wrapper').find('.sidebar').toggleClass('minified');
    }
  }, function() {
    const hovered = $(this).hasClass('hover');

    if (hovered) {
      $(this).removeClass('hover');
      $('.wrapper').find('.sidebar').toggleClass('minified');
    }
  });
});
