$(document).on('change', '#quotation_payment_term_id', function (e) {
  var paymentTermId = $('#quotation_payment_term_id').find(':selected').val();

  $.ajax({
    method: 'GET',
    url: `/payment_terms/${paymentTermId}`,
    dataType: 'json',
    success: function(data) {
      $('.payment-term-descriptions').val(data.payment_term.description);
    }
  });
});

$(document).on('change', '#quotation_delivery_term_id', function (e) {
  var deliveryTermId = $('#quotation_delivery_term_id').find(':selected').val();

  $.ajax({
    method: 'GET',
    url: `/delivery_terms/${deliveryTermId}`,
    dataType: 'json',
    success: function(data) {
      $('.delivery-term-descriptions').val(data.delivery_term.description);
    }
  });
});
